<template>
	<div class="Attendance_rules_set">
		<div class="head">
			<h4>考勤规则设置</h4>
		</div>
		<div class="body">
			<div class="form-wrap">
				<div class="fitem req">
					<label class="label">规则名称：</label>
					<div class="value">
						<el-input v-model="form.title" placeholder="请输入考勤规则名称"></el-input>
					</div>
				</div>
				<div class="fitem req">
					<label class="label">生效日期：</label>
					<div class="value">
						<el-date-picker v-model="form.startDay" type="date" placeholder="请选择生效日期" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
				</div>
				<div class="fitem req">
					<label class="label">应出勤天数：</label>
					<div class="value">
						<el-input-number v-model="form.workDay" controls-position="right" :min="1"></el-input-number>
						<p>天</p>
					</div>
				</div>
				<div class="fitem req">
					<label class="label">考勤类型：</label>
					<div class="value">
						<el-radio-group v-model="rule_type" @change="change_rule_type">
							<el-radio :label="1">固定排班</el-radio>
							<el-radio :label="2">3班倒</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="fitem req">
					<label class="label">上下班次数：</label>
					<div class="value">
						<el-radio-group v-model="form.workNum" @change="change_workNum">
							<el-radio v-if="rule_type == 1 || rule_type == 2" label="1">1次上下班</el-radio>
							<el-radio v-if="rule_type == 1" label="2">2次上下班</el-radio>
						</el-radio-group>
					</div>
				</div>

				<div class="bitem loop-item" v-for="(item, i) in cardRules" :key="i" :class="{ hidden: !item.is_show }">
					<div class="title" v-if="rule_type == 1">第 {{ i + 1 }} 次上下班</div>
					<div class="title" v-if="rule_type == 2">{{banci_title_map[i]}}</div>
					<div class="inner">
						<div class="fitem req">
							<label>上班时间</label>
							<div class="value">
								<el-time-picker v-model="item.startHour" format="HH:mm" value-format="HH:mm" :picker-options="{
							selectableRange: '00:00:00 - 23:59:59'
						}" placeholder="任意时间点"></el-time-picker>
							</div>
						</div>
						<div class="fitem req">
							<label>下班时间</label>
							<div class="value">
								<el-time-picker v-model="item.endHour" format="HH:mm" value-format="HH:mm" :picker-options="{
							selectableRange: '00:00:00 - 23:59:59'
						}" placeholder="任意时间点"></el-time-picker>
							</div>
						</div>
						<div class="fitem req">
							<label>上班晚</label>
							<div class="value">
								<el-input-number v-model="item.startLate" controls-position="right" :min="1"></el-input-number>
								<p>分钟记为迟到</p>
							</div>
						</div>
						<div class="fitem req">
							<label>下班早</label>
							<div class="value">
								<el-input-number v-model="item.endLeave" controls-position="right" :min="1"></el-input-number>
								<p>分钟记为早退</p>
							</div>
						</div>
						<div class="fitem req">
							<label>上班晚</label>
							<div class="value">
								<el-input-number v-model="item.startMissing" controls-position="right" :min="1"></el-input-number>
								<p>分钟记为缺卡</p>
							</div>
						</div>
						<div class="fitem req">
							<label>下班早</label>
							<div class="value">
								<el-input-number v-model="item.endMissing" controls-position="right" :min="1"></el-input-number>
								<p>分钟记为缺卡</p>
							</div>
						</div>
						<div class="fitem req">
							<label>最早提前</label>
							<div class="value">
								<el-input-number v-model="item.startLimit" controls-position="right" :min="1"></el-input-number>
								<p>分钟进行打卡</p>
							</div>
						</div>
						<div class="fitem req">
							<label>最晚延后</label>
							<div class="value">
								<el-input-number v-model="item.endLimit" controls-position="right" :min="1"></el-input-number>
								<p>分钟进行打卡</p>
							</div>
						</div>
					</div>
				</div>
				<div class="fitem req">
					<label>总工时</label>
					<div class="value">
						<p>{{total_hour.toFixed(2) ||8}}小时</p>
					</div>
				</div>
			</div>

			<!-- 暂时先把考勤设置 放到【员工管理】-【组织架构】下 -->
			<!-- <div class="form-wrap">
				<div class="fitem req">
					<label>选择考勤部门</label>
					<div class="value">
						<bumen_cascader ref="bumen_cascader" @confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
			</div> -->
			<div class="btns">
				<el-button type="primary" @click="do_save()">提交</el-button>
				<el-button @click="$router.back()">取消</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择

	export default {
		components: {
			bumen_cascader
		},
		data() {
			return {
				id: 0,
				rule_type: 1, //考勤类型 固定排班 -> 2 次    3班倒 => 1次上下班
				form: {
					"id": 0,
					"title": "",
					"workDay": "24",
					"type": "1", //类型：1-考勤规则 2-下井设置 3-下井带班 4-免考勤
					"startDay": "",
					"workNum": "2",
					"hourNum": "",
					"departIds": "",
					"departItems": "",
					"cardRules": "",
				},
				cardRules: [{
						is_show: true,
						"startHour": "08:00",
						"endHour": "12:00",
						"startLate": "5",
						"endLeave": "0",
						"startMissing": "30",
						"endMissing": "30",
						"startLimit": "120",
						"endLimit": "480"
					},
					{
						is_show: true,
						"startHour": "08:00",
						"endHour": "12:00",
						"startLate": "5",
						"endLeave": "0",
						"startMissing": "30",
						"endMissing": "30",
						"startLimit": "120",
						"endLimit": "480"
					},
					{
						is_show: false,
						"startHour": "08:00",
						"endHour": "12:00",
						"startLate": "5",
						"endLeave": "0",
						"startMissing": "30",
						"endMissing": "30",
						"startLimit": "120",
						"endLimit": "480"
					},
				],

				banci_title_map: {
					0: '8点班',
					1: '4点班',
					2: '0点班',
				},

			};
		},
		computed: {
			total_hour() {
				// 8, //总工时数
				let hourNum = 8;
				if (this.rule_type == 1) { //固定排班
					let items = this.cardRules.filter(v => v.is_show && v.startHour && v.endHour)
					let total_hour = 0;
					items.forEach(v => {
						let item = v;

						let hour_diff = parseInt(item.endHour) - parseInt(item.startHour);
						let minute_diff_for_hour = parseFloat((parseInt(item.endHour.split(':')[1]) - parseInt(item.startHour
							.split(':')[
								1])) / 60) || 0;
						total_hour += (hour_diff + minute_diff_for_hour);
					})
					if (total_hour > 0) {
						hourNum = total_hour;
					}
				} else if (this.rule_type == 2) { //3班倒
					let items = this.cardRules.filter(v => v.is_show && v.startHour && v.endHour)
					let total_hour = 0;
					items.forEach(v => {
						let item = v;

						let hour_diff = parseInt(item.endHour) - parseInt(item.startHour);
						let minute_diff_for_hour = parseFloat((parseInt(item.endHour.split(':')[1]) - parseInt(item.startHour
							.split(':')[
								1])) / 60) || 0;
						total_hour += (hour_diff + minute_diff_for_hour);
					})
					if (total_hour > 0) {
						hourNum = total_hour / 3;
					}
				}

				return hourNum
			}
		},
		watch: {},
		created() {
			this.setView()
		},
		mounted() {},
		methods: {
			change_rule_type(value) {
				console.log('切换考勤类型', value)

				if (value == 1) { //固定排班  2次
					this.form.workNum = '1'
					this.cardRules[1].is_show = false;
					this.cardRules[2].is_show = false;
				} else if (value == 2) { //3班倒 1次上下班
					this.form.workNum = '1'
					this.cardRules[1].is_show = true;
					this.cardRules[2].is_show = true;
				}
			},

			setView() {
				if (this.$route.query.id) {
					this.id = this.$route.query.id
					this.query_detail()
				}
			},
			query_detail() {
				this.$api("kaoqin/getRuleInfo", {
					id: this.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						let info = data.info;
						this.rule_type = data.rule_type || '';


						this.form = {
							"id": data.id || '',
							"title": data.title || '',
							"type": data.type || '',
							"startDay": data.startDay || '',
							"departIds": info.departIds || '',
							"departItems": info.departItems || '',
							"workDay": info.workDay || '',
							"workNum": info.workNum + '' || '',
							"hourNum": info.hourNum || '',
							"cardRules": '',
						}
						//规则
						if (info.cardRules) {
							this.cardRules = info.cardRules.map(v => ({
								...v,
								is_show: true
							}))

							if (this.cardRules.length == 1) {
								this.cardRules.push({
									is_show: false,
									"startHour": "08:00",
									"endHour": "12:00",
									"startLate": "5",
									"endLeave": "0",
									"startMissing": "30",
									"endMissing": "30",
									"startLimit": "120",
									"endLimit": "480"
								})
								this.cardRules.push({
									is_show: false,
									"startHour": "08:00",
									"endHour": "12:00",
									"startLate": "5",
									"endLeave": "0",
									"startMissing": "30",
									"endMissing": "30",
									"startLimit": "120",
									"endLimit": "480"
								})
							} else if (this.cardRules.length == 2) {
								this.cardRules.push({
									is_show: false,
									"startHour": "08:00",
									"endHour": "12:00",
									"startLate": "5",
									"endLeave": "0",
									"startMissing": "30",
									"endMissing": "30",
									"startLimit": "120",
									"endLimit": "480"
								})
							}
						}
						//部门
						if (info.departItems) {
							this.$nextTick(() => {
								this.$refs.bumen_cascader.init({
									type: '设置数据',
									value: info.departItems
								});
							})

						}
					}
				});
			},


			//上下班次数  
			// 1 1次上下班
			// 2 2次上下班
			change_workNum(value) {
				console.log('value', value)
				if (value == 1) {
					this.cardRules[1].is_show = false;
					this.cardRules[2].is_show = false;
				} else if (value == 2) {
					this.cardRules[1].is_show = true;
					this.cardRules[2].is_show = false;
				}
			},

			do_save() {
				if (!this.form.title) {
					alertErr('请填写考勤规则名称')
					return
				}
				if (!this.form.startDay) {
					alertErr('请选择生效日期')
					return
				}
				if (!this.form.workDay) {
					alertErr('请输入应出勤天数')
					return
				}
				if (!this.form.workNum) {
					alertErr('请输入上下班次数')
					return
				}

				for (let i = 0; i < this.cardRules.length; i++) {
					let data = this.cardRules[i]
					if (data.is_show) {
						if (!data.startHour) {
							alertErr(`第${i + 1}次` + '请选择上班时间')
							return
						}
						if (!data.endHour) {
							alertErr('第${i+1}次`+请选择下班时间')
							return
						}
						if (!data.startLate) {
							alertErr('第${i+1}次`+请输入上班迟到时间')
							return
						}
						if (!data.endLeave) {
							alertErr('第${i+1}次`+请输入下班早退时间')
							return
						}

						if (!data.startMissing) {
							alertErr('第${i+1}次`+请输入上班缺卡时间')
							return
						}
						if (!data.endMissing) {
							alertErr('第${i+1}次`+请输入下班缺口时间')
							return
						}

						if (!data.startMissing) {
							alertErr('请输入最早提前打卡时间')
							return
						}
						if (!data.endLimit) {
							alertErr('请输入最晚提延后卡时间')
							return
						}
					}
				}


				let need_fill_rules = this.cardRules.filter(v => v.is_show)
				let empty_rule = null
				need_fill_rules.forEach(v => {
					if (!empty_rule && !v.startHour || !v.endHour) {
						empty_rule = v;
					}
				})

				if (empty_rule) {
					if (!empty_rule.startHour) {
						alertErr('请填写上班时间')
						return
					}
				}
				let params = {
					...this.form,
					rule_type: this.rule_type
				}
				let item = need_fill_rules[0]
				let hour_diff = parseInt(item.endHour) - parseInt(item.startHour);
				let minute_diff_for_hour = parseFloat((parseInt(item.endHour.split(':')[1]) - parseInt(item.startHour.split(':')[
					1])) / 60) || 0;
				params.hourNum = hour_diff + minute_diff_for_hour;


				let cardRules = need_fill_rules.map(v => ({
					...v
				}))
				cardRules.forEach(v => {
					delete v.is_show
				})
				params.cardRules = JSON.stringify(cardRules)
				params.id = this.id
				this.$log('要提交的表单', params)

				this.$api(
					'kaoqin/saveRule', params,
					'post'
				).then((res) => {
					alert(res)
					if (res.code == 200) {
						this.$router.back()
					}
				});
			},

			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
				let last_id_arr = value.map(arr => arr[arr.length - 1])
				this.form.departIds = last_id_arr.join(',')
				this.form.departItems = JSON.stringify(value)
			}

		}
	};
</script>

<style scoped lang="less">
	.loop-item.hidden {
		display: none;
	}

	.bor {
		border: 1px solid red;
	}

	.Attendance_rules_set {
		background: #fff;
		border-radius: 8px 8px 8px 8px;
		min-height: calc(100vh);

		.body {
			padding: 35px;

			.btns {
				padding: 30px 0;
				margin-left: 115px;

				button {
					min-width: 179px;
				}
			}

			.form-wrap+.form-wrap {
				padding-top: 30px;
				border-top: 1px solid #ccc;
			}

			.form-wrap {
				padding: 0px 10px;

				// width: 0;
				.bitem {
					width: 890px;
					min-height: 234px;
					padding: 20px 14px 10px;
					margin-bottom: 16px;
					background: #f8f8f8;
					border-radius: 4px;

					.title {
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						padding: 0 10px 10px;
					}

					.inner {
						display: flex;
						flex-wrap: wrap;

						.fitem {
							flex: 1;

							label {}
						}
					}
				}

				.fitem.req {
					label {
						&:before {
							display: inline-block;
							content: '*';
							padding-right: 5px;
							text-align: right;
							color: red;
							line-height: 40px;
						}
					}
				}

				.fitem {
					display: flex;
					margin-bottom: 16px;

					&>label {

						&::after {
							// content: ' :';
						}

						display: inline-block;
						min-width: 115px;
						text-align: right;
						padding-right: 10px;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 40px;
					}

					.value {
						display: flex;
						align-items: center;
						flex: 1;

						p {
							margin-left: 14px;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
						}

						// .bor();
					}
				}
			}
		}

		.head {
			padding: 20px 17px;

			h4 {
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}
		}
	}
</style>